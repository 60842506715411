import * as React from "react"

import Seo from "../components/seo"
import LayoutTwo from "../components/layoutTwo"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
    <LayoutTwo>
        <div className="layout-padding-top"></div>
        <div className="error-page-wrapper d-flex align-items-center">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xl={7} className="text-center">
                        <h1 className="atlas-heading-h3">Ooops!</h1>
                          <div className="divider"></div>
                          <p className="atlas-para">We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <Link to="/" className="button button-primary">Homepage</Link>
                            </li>
                            <li className="list-inline-item">
                              <Link to="/contact/" className="button button-secondary-outline">Contact us</Link>
                            </li>
                          </ul>
                    </Col>
                </Row>
            </Container>
      </div>
    </LayoutTwo>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
